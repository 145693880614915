import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import pageBanner from '../assets/images/bg/Contact.jpg';
import { HTPPURL } from '../constant/Url';
import { Helmet } from 'react-helmet-async';

const Contact = () => {
  const [contactform, setContactForm] = useState({
    name: "",
    email: "",
    subject: "",
    message: ""
  });

  const onSubmitForm = async (e) => {
    e.preventDefault();
    const { name, email, subject, message } = contactform;
    if (!name || !email || !subject || !message) {
      alert('All fields are required!');
    } else {
      const response = await fetch(HTPPURL + 'contact', {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(contactform)
      });
      const responsedata = await response.json();
      if (responsedata?.status) {
        setContactForm({ ...contactform, name: "", email: "", subject: "", message: "" });
        alert(responsedata?.message);
      }
    }
  }

  return (
    <>
      <Helmet>
        <title>Contact Bilvaleaf for Top-Tier Web Development and Digital Marketing Solutions</title>
        <meta name="description" content="Reach out to Bilvaleaf Private Limited for expert web development, design, BPO/KPO services, and digital marketing solutions tailored to you." />
        <link rel="canonical" href="https://bilvaleaf.com/contact" />
      </Helmet>
      {/* Page Banner Start */}
      <div className="section page-banner-section" style={{ backgroundImage: `url(${pageBanner})` }}>
        <div className="shape-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="726.5px" height="726.5px">
            <path fillRule="evenodd" stroke="rgb(255, 255, 255)" strokeWidth="1px" strokeLinecap="butt" strokeLinejoin="miter" opacity="0.302" fill="none" d="M28.14,285.269 L325.37,21.217 C358.860,-8.851 410.655,-5.808 440.723,28.14 L704.777,325.36 C734.846,358.859 731.802,410.654 697.979,440.722 L400.955,704.776 C367.132,734.844 315.338,731.802 285.269,697.978 L21.216,400.954 C-8.852,367.132 -5.808,315.337 28.14,285.269 Z" />
          </svg>
        </div>
        <div className="shape-4">
          <svg xmlns="http://www.w3.org/2000/svg" width="972.5px" height="970.5px">
            <path fillRule="evenodd" stroke="rgb(255, 255, 255)" strokeWidth="1px" strokeLinecap="butt" strokeLinejoin="miter" fill="none" d="M38.245,381.102 L435.258,28.158 C480.467,-12.32 549.697,-7.964 589.888,37.244 L942.832,434.257 C983.23,479.466 978.955,548.697 933.746,588.888 L536.733,941.832 C491.524,982.23 422.293,977.955 382.103,932.745 L29.158,535.732 C-11.32,490.523 -6.963,421.293 38.245,381.102 Z" />
          </svg>
        </div>
        <div className="container">
          <div className="page-banner-wrap">
            <div className="row">
              <div className="col-lg-12">
                <div className="page-banner text-center">
                  <h2 className="title">Contact Us</h2>
                  <ul className="breadcrumb justify-content-center">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Contact Us</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Page Banner End */}

      {/* Contact Info Start */}
      <div className="section contact-info-section section-padding-02">
        <div className="container">
          <div className="contact-info-wrap">
            <div className="row">
              <div className="col-lg-4 col-sm-6">
                <div className="single-contact-info text-center">
                  <div className="info-icon">
                    <img src="assets/images/info-1.png" alt="info-1.png" />
                  </div>
                  <div className="info-content">
                    <Link to="tel: +91 9816333105">
                      <h5 className="title">Give us a call</h5>
                      <p>+91 9816333105</p>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="single-contact-info text-center">
                  <div className="info-icon">
                    <img src="assets/images/info-2.png" alt="info-2.png" />
                  </div>
                  <div className="info-content">
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      to="https://mail.google.com/mail/?view=cm&fs=1&to=admin@bilvaleaf.com">
                      <h5 className="title">Drop us a line</h5>
                      <p>admin@bilvaleaf.com</p>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="single-contact-info text-center">
                  <div className="info-icon">
                    <img src="assets/images/info-3.png" alt="info-3.png" />
                  </div>
                  <div className="info-content">
                    <Link onClick={() => document.getElementById('map-location').scrollIntoView({ behavior: 'smooth' })}>
                      <h5 className="title">Visit our office</h5>
                      <p>Hazaribagh, India</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Contact Info End */}

      {/* Contact Start */}
      <div className="section techwix-contact-section techwix-contact-section-02 techwix-contact-section-03 section-padding-02">
        <div className="container">
          <div className="contact-wrap">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="contact-form">
                  <div className="contact-form-wrap">
                    <div className="heading-wrap text-center">
                      <span className="sub-title"> request a quote</span>
                      <h3 className="title">How May We Help You!</h3>
                    </div>
                    <form action="#" method='post' onSubmit={(e) => onSubmitForm(e)}>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="single-form">
                            <input type="text" placeholder="Name *" name="name" value={contactform.name} onChange={(e) => setContactForm({ ...contactform, name: e.target.value })} />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="single-form">
                            <input type="email" placeholder="Email *" name="email" value={contactform.email} onChange={(e) => setContactForm({ ...contactform, email: e.target.value })} />
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="single-form">
                            <input type="text" placeholder="Subject *" name="subject" value={contactform.subject} onChange={(e) => setContactForm({ ...contactform, subject: e.target.value })} />
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="single-form">
                            <textarea placeholder="Write A Message*" name="message" value={contactform.message} onChange={(e) => setContactForm({ ...contactform, message: e.target.value })}></textarea>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="form-btn">
                            <button className="btn" type="submit">Send Message</button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Contact End  */}

      {/* Contact Map Start */}
      <div className="section contact-map-section">
        <div className="contact-map-wrap" id="map-location">
          <iframe marginheight="0" marginwidth="0" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d58320.87540732146!2d85.32400740326294!3d23.993844974047292!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f49c114f26548f%3A0xbd45e868bb61961f!2sHazaribagh%2C%20Jharkhand!5e0!3m2!1sen!2sin!4v1717323313466!5m2!1sen!2sin" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
      {/* Contact Map End */}
    </>
  )
}
export default Contact; 