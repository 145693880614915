import React, { useEffect, useState } from "react";
import chooseBg from '../../assets/images/bg/choose-us-bg.jpg';
import chooseUs1 from '../../assets/images/choose-us1.jpg';
import chooseUs2 from '../../assets/images/choose-us2.jpg';
import chooseUs3 from '../../assets/images/choose-us3.jpg';
import { Link } from "react-router-dom";

const ChooseUsSection = () => {
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 429);
  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 429);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <div
      className="section techwix-choose-us-section section-padding"
      style={{ backgroundImage: `url(${chooseBg})` }}
    >
      <div className="container">
        {/* Choose Us Wrap Start */}
        <div className="choose-us-wrap">
          <div className="section-title text-center">
            <h3 data-aos={isWideScreen ? "fade-left": "undefined"} className="sub-title">REASON TO CHOOSE US</h3>
            <h2 data-aos={isWideScreen ? "fade-right": "undefined"} className="title"> We provide truly prominent IT solutions.</h2>
          </div>
          <div className="choose-us-content-wrap">
            <div className="row">
              <div data-aos={isWideScreen ? "zoom-in":"undefined"} className="col-lg-4 col-md-6">
                {/* Choose Us Item Start */}
                <div className="choose-us-item">
                  <div className="choose-us-img">
                    <a href="#">
                      <img src={chooseUs1} alt="choose-us1.jpg" />
                    </a>
                    <div className="choose-us-content">
                      <h3 className="title">Highly professional team members</h3>
                      <p>
                        Our highly professional team members exhibit strong expertise, clear communication, and reliable accountability. They collaborate well, adapt to changes, and solve problems efficiently.
                      </p>
                    </div>
                  </div>
                </div>
                {/* Choose Us Item End */}
              </div>
              <div data-aos={isWideScreen ? "zoom-in": "undefined"} className="col-lg-4 col-md-6">
                {/* Choose Us Item Start */}
                <div className="choose-us-item">
                  <div className="choose-us-img">
                    <a href="#">
                      <img src={chooseUs2} alt="choose-us2.jpg" />
                    </a>
                    <div className="choose-us-content">
                      <h3 className="title">Innovation</h3>
                      <p>
                        Innovation as a core value drives a company to continuously seek new ideas, improve processes, and develop cutting-edge products. It fosters a culture of creativity and adaptability, enabling the company to stay competitive and respond effectively to market changes.
                      </p>
                    </div>
                  </div>
                </div>
                {/* Choose Us Item End */}
              </div>
              <div data-aos={isWideScreen ? "zoom-in": "undefined"} className="col-lg-4 col-md-6">
                {/* Choose Us Item Start */}
                <div className="choose-us-item">
                  <div className="choose-us-img">
                    <a href="#">
                      <img src={chooseUs3} alt="choose-us3.jpg" />
                    </a>
                    <div className="choose-us-content">
                      <h3 className="title">Excellence</h3>
                      <p>
                        Excellence as a company value signifies a commitment to the highest standards in all aspects of business. It drives the pursuit of quality, continuous improvement, and superior performance.
                      </p>
                    </div>
                  </div>
                </div>
                {/* Choose Us Item End */}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="more-choose-content text-center">
                  <p>
                    <Link to="/about">
                       More reasons&nbsp;<i className="fas fa-long-arrow-alt-right"></i>
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Choose Us Wrap End */}
      </div>
    </div>
  );
};

export default ChooseUsSection;
