import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";

const Layout = ({ children }) => {
   const location = useLocation();
   
   useEffect(() => {
      const notBlogdetails = location.pathname.includes('blog-details');
      if(!notBlogdetails){
         window.scrollTo(0, 0)
      }
   }, [location.pathname]);
   return (
      <div className="main-wrapper">
         <Header />
         {children}
         <Footer />
      </div>
   )
}

export default Layout;