import React, { useEffect, useState } from "react";
import ctaBg from '../../assets/images/bg/cta-bg2.jpg'
import ctaIcon from '../../assets/images/cta-icon2.png';
import { Link } from "react-router-dom";

const CtaSection = () => {
    const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 429);
  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 429);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
    return (
        <div data-aos={isWideScreen ? "fade-down-right" : "undefined"} className="section techwix-cta-section-02">
            <div className="container">
                <div className="cta-wrap" style={{ backgroundImage: `url(${ctaBg})` }}>
                    <div className="row align-items-center">
                        <div className="col-xl-9 col-lg-8">
                            <div className="cta-content">
                                <div className="cta-icon">
                                    <img src={ctaIcon} alt="cta-icon2.png" />
                                </div>
                                <p>We’re Delivering the best customer Experience</p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4">
                            <div className="cta-btn">
                                <Link className="btn btn-class" to="/contact">+919816333105</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CtaSection;
