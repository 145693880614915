import React from 'react';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import pageBanner from '../assets/images/bg/page-banner.jpg';
import ctaBg from '../assets/images/bg/cta-bg.jpg';
import ctaIcon2 from '../assets/images/cta-icon2.png';
import testiCon from '../assets/images/testi-icon.png'
import testi from '../assets/images/testi-img.png';
import testiBg from '../assets/images/bg/testi-bg.jpg';
import testi1 from '../assets/images/testi-1.jpg';
import testi2 from '../assets/images/testi-2.jpg';
import testi3 from '../assets/images/testi-3.jpg';
import testi4 from '../assets/images/testi-4.jpg';
import anne from '../assets/images/Anne.jpg';
import kamal from '../assets/images/kamal.jpg';
import ross from '../assets/images/Ross.jpg';
import testIcon2 from '../assets/images/testi-icon2.png';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const Testimonial = () => {

    return (
        <>
            <Helmet>
                <title>Bilvaleaf Testimonials: Hear What Our Clients Say About Us</title>
                <meta name="description" content="See how our clients rate our web development, graphic design, BPO/KPO, and digital marketing services. Real feedback, real results." />
                <link rel="canonical" href="https://bilvaleaf.com/testimonial" />
            </Helmet>
            {/* Page Banner Start */}
            <div className="section page-banner-section" style={{ backgroundImage: `url(${pageBanner})` }}>
                <div className="shape-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="726.5px" height="726.5px">
                        <path fillRule="evenodd" stroke="rgb(255, 255, 255)" strokeWidth="1px" strokeLinecap="butt" strokeLinejoin="miter" opacity="0.302" fill="none" d="M28.14,285.269 L325.37,21.217 C358.860,-8.851 410.655,-5.808 440.723,28.14 L704.777,325.36 C734.846,358.859 731.802,410.654 697.979,440.722 L400.955,704.776 C367.132,734.844 315.338,731.802 285.269,697.978 L21.216,400.954 C-8.852,367.132 -5.808,315.337 28.14,285.269 Z" />
                    </svg>
                </div>
                <div className="shape-4">
                    <svg xmlns="http://www.w3.org/2000/svg" width="972.5px" height="970.5px">
                        <path fillRule="evenodd" stroke="rgb(255, 255, 255)" strokeWidth="1px" strokeLinecap="butt" strokeLinejoin="miter" fill="none" d="M38.245,381.102 L435.258,28.158 C480.467,-12.32 549.697,-7.964 589.888,37.244 L942.832,434.257 C983.23,479.466 978.955,548.697 933.746,588.888 L536.733,941.832 C491.524,982.23 422.293,977.955 382.103,932.745 L29.158,535.732 C-11.32,490.523 -6.963,421.293 38.245,381.102 Z" />
                    </svg>
                </div>
                <div className="container">
                    <div className="page-banner-wrap">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="page-banner text-center">
                                    <h2 className="title">Testimonial</h2>
                                    <ul className="breadcrumb justify-content-center">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Testimonial</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Page Banner End --> */}

            {/* Testimonial Start */}
            <div className="section techwix-testimonial-section-02 techwix-testimonial-section-03 section-padding">
                <div className="container">
                    <div className="testimonial-wrap-02">
                        <div className="swiper-container testimonial-02-active">
                            <div className="swiper-wrapper">
                                <Swiper
                                    spaceBetween={10}
                                    slidesPerView={1}
                                    breakpoints={{
                                        // When screen width is >= 640px (tablet and above), show 3 slides
                                        640: {
                                            slidesPerView: 2,
                                        },
                                    }}
                                    onSlideChange={() => console.log('slide change')}
                                    onSwiper={(swiper) => console.log(swiper)}
                                    loop={true}
                                    pagination={{ clickable: true }}
                                >
                                    <SwiperSlide>
                                        <div className="swiper-slide">
                                            <div className="single-testimonial-02">
                                                <div className="testimonial-thumb">
                                                    <img src={ross} alt="Ross.jpg" />
                                                </div>
                                                <div className="testimonial-content">
                                                    <img src={testiCon} alt="Ross.jpg" />
                                                    <p>Exceptional service that consistently exceeds our expectations. The team is knowledgeable, responsive, and a true partner in our success.</p>
                                                    <span className="name">Ross Taylor</span>
                                                    <span className="designation"> / Designer, Templatefirm</span>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="swiper-slide">
                                            <div className="single-testimonial-02">
                                                <div className="testimonial-thumb">
                                                    <img src={testi4} alt="Ross.jpg" />
                                                </div>
                                                <div className="testimonial-content">
                                                    <img src={testiCon} alt="Ross.jpg" />
                                                    <p>Outstanding IT support with prompt solutions and a high level of professionalism. Their innovative approach has significantly improved our operations.</p>
                                                    <span className="name">Roman</span>
                                                    <span className="designation"> / Vice president, Blogsenate</span>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="swiper-slide">
                                            <div className="single-testimonial-02">
                                                <div className="testimonial-thumb">
                                                    <img src={anne} alt="Anne.jpg" />
                                                </div>
                                                <div className="testimonial-content">
                                                    <img src="assets/images/testi-icon.png" alt="testi-icon.png" />
                                                    <p>A reliable and efficient IT service provider. Their expertise and dedication have been invaluable to our business.</p>
                                                    <span className="name">Anne marie</span>
                                                    <span className="designation"> / CEO, Weddingmoments</span>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="swiper-slide">
                                            <div className="single-testimonial-02">
                                                <div className="testimonial-thumb">
                                                    <img src={kamal} alt="Kamal.jpeg" />
                                                </div>
                                                <div className="testimonial-content">
                                                    <img src="assets/images/testi-icon.png" alt="testi-icon.png" />
                                                    <p>Remarkable customer service and technical proficiency. They always go the extra mile to ensure our needs are met.</p>
                                                    <span className="name">Kamal Khanna</span>
                                                    <span className="designation"> / Manager, Historical</span>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="swiper-slide">
                                            <div className="single-testimonial-02">
                                                <div className="testimonial-thumb">
                                                    <img src={testi3} alt="testi-icon.png" />
                                                </div>
                                                <div className="testimonial-content">
                                                    <img src="assets/images/testi-icon.png" alt="testi-icon.png" />
                                                    <p>Top-notch IT services delivered with excellence and attention to detail. They transform challenges into seamless solutions, enhancing our productivity.</p>
                                                    <span className="name">Anant Verma</span>
                                                    <span className="designation"> / CEO, Templatefirm</span>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                            <div className="swiper-pagination">{SwiperCore.use([Pagination])}</div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Testimonial End */}

            {/* Testimonial Start */}
            <div className="section techwix-testimonial-section-02 section-padding" style={{ backgroundImage: `url(${ctaBg})` }}>
                <div className="container">
                    <div className="testimonial-wrap-02">
                        <div className="swiper-container testimonial-02-active">
                            <div className="swiper-wrapper">
                                <Swiper
                                    spaceBetween={10}
                                    slidesPerView={1}
                                    breakpoints={{
                                        // When screen width is >= 640px (tablet and above), show 3 slides
                                        640: {
                                            slidesPerView: 2,
                                        },
                                    }}
                                    onSlideChange={() => console.log('slide change')}
                                    onSwiper={(swiper) => console.log(swiper)}
                                    loop={true}
                                    pagination={{ clickable: true }}
                                >
                                    <SwiperSlide>
                                        <div className="swiper-slide">
                                            <div className="single-testimonial-02">
                                                <div className="testimonial-thumb">
                                                    <img src={testi1} alt="testi-icon.png" />
                                                </div>
                                                <div className="testimonial-content">
                                                    <img src={testIcon2} alt="testi-icon.png" />
                                                    <p>Accelerate innovation with world-class tech teams Beyond more stoic this along goodness hey this this wow manatee </p>
                                                    <span className="name">Mike Holder </span>
                                                    <span className="designation"> / CEO, Harlond inc</span>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="swiper-slide">
                                            <div className="single-testimonial-02">
                                                <div className="testimonial-thumb">
                                                    <img src={testi2} alt="testi-icon.png" />
                                                </div>
                                                <div className="testimonial-content">
                                                    <img src={testIcon2} alt="testi-icon.png" />
                                                    <p>Accelerate innovation with world-class tech teams Beyond more stoic this along goodness hey this this wow manatee </p>
                                                    <span className="name">Mike Fermalin </span>
                                                    <span className="designation"> / CEO, Harlond inc</span>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="swiper-slide">
                                            <div className="single-testimonial-02">
                                                <div className="testimonial-thumb">
                                                    <img src={testi1} alt="testi-icon.png" />
                                                </div>
                                                <div className="testimonial-content">
                                                    <img src={testIcon2} alt="testi-icon.png" />
                                                    <p>Accelerate innovation with world-class tech teams Beyond more stoic this along goodness hey this this wow manatee </p>
                                                    <span className="name">Mike Holder </span>
                                                    <span className="designation"> / CEO, Harlond inc</span>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                            <div className="swiper-pagination">{SwiperCore.use([Pagination])}</div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Testimonial End */}

            {/* Testimonial Start */}
            <div className="section techwix-testimonial-section section-padding-02">
                <div className="container">
                    <div className="testimonial-wrap" style={{ backgroundImage: `url(${testiBg})` }}>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="testimonial-img">
                                    <img src={testi} alt="testi-icon.png" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="testimonial-content-wrap">
                                    <div className="swiper-container testimonial-active">
                                        <div className="swiper-wrapper">
                                            <Swiper
                                                spaceBetween={5}
                                                slidesPerView={1}
                                                loop={true}
                                                onSlideChange={() => console.log('slide change')}
                                                onSwiper={(swiper) => console.log(swiper)}
                                            >
                                                <SwiperSlide>
                                                    <div className="swiper-slide">
                                                        <div className="single-testimonial">
                                                            <img src={testiCon} alt="testi-icon.png" />
                                                            <p>Accelerate innovation with world-class tech teams Beyond more stoic this along goodness hey this this wow manatee </p>
                                                            <span className="name">Mike Holder </span>
                                                            <span className="designation"> / CEO, Harlond inc</span>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide>

                                                    <div className="swiper-slide">
                                                        <div className="single-testimonial">
                                                            <img src={testiCon} alt="testi-icon.png" />
                                                            <p>Accelerate innovation with world-class tech teams Beyond more stoic this along goodness hey this this wow manatee </p>
                                                            <span className="name">Mike Holder </span>
                                                            <span className="designation"> / CEO, Harlond inc</span>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide>

                                                    <div className="swiper-slide">
                                                        <div className="single-testimonial">
                                                            <img src={testiCon} alt="testi-icon.png" />
                                                            <p>Accelerate innovation with world-class tech teams Beyond more stoic this along goodness hey this this wow manatee </p>
                                                            <span className="name">Mike Holder </span>
                                                            <span className="designation"> / CEO, Harlond inc</span>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            </Swiper>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Testimonial End */}

            {/* Cta Start */}
            <div className="section techwix-cta-section-02 section-padding-02">
                <div className="container">
                    <div className="cta-wrap" style={{ backgroundImage: `url(${ctaBg})` }}>
                        <div className="row align-items-center">
                            <div className="col-xl-9 col-lg-8">
                                <div className="cta-content">
                                    <div className="cta-icon">
                                        <img src={ctaIcon2} alt="testi-icon.png" />
                                    </div>
                                    <p>We’re Delivering the best customer Experience</p>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4">
                                <div className="cta-btn">
                                    <Link className="btn btn-class" to="tel: +91 9816333105">+91 9816333105</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Cta End */}
        </>
    )
}

export default Testimonial;