import React from 'react';
import pageBanner from '../assets/images/bg/page-banner.jpg';
import team1 from '../assets/images/team/team-1.jpg';
import team2 from '../assets/images/team/team-2.jpg';
import team3 from '../assets/images/team/team-3.jpg';
import team4 from '../assets/images/team/team-4.jpg';
import team5 from '../assets/images/team/team-5.jpg';
import team6 from '../assets/images/team/team-6.jpg';
import team7 from '../assets/images/team/team-7.jpg';
import team8 from '../assets/images/team/team-8.jpg';
import team from '../assets/images/team/team-avatar.png';

import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const Teams = () => {
    return (
        <>
            <Helmet>
                <title>Our Team of Expert Web Designers and Digital Marketers | Bilvaleaf</title>
                <meta name="description" content="Meet our dynamic team of web developers, designers, and digital marketers, driving innovation and results at Bilvaleaf Private Limited." />
                <link rel="canonical" href="https://bilvaleaf.com/team" />
            </Helmet>
            {/* Page Banner Start */}
            <div className="section page-banner-section" style={{ backgroundImage: `url(${pageBanner})` }}>
                <div className="shape-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="726.5px" height="726.5px">
                        <path fillRule="evenodd" stroke="rgb(255, 255, 255)" strokeWidth="1px" strokeLinecap="butt" strokeLinejoin="miter" opacity="0.302" fill="none" d="M28.14,285.269 L325.37,21.217 C358.860,-8.851 410.655,-5.808 440.723,28.14 L704.777,325.36 C734.846,358.859 731.802,410.654 697.979,440.722 L400.955,704.776 C367.132,734.844 315.338,731.802 285.269,697.978 L21.216,400.954 C-8.852,367.132 -5.808,315.337 28.14,285.269 Z" />
                    </svg>
                </div>
                <div className="shape-4">
                    <svg xmlns="http://www.w3.org/2000/svg" width="972.5px" height="970.5px">
                        <path fillRule="evenodd" stroke="rgb(255, 255, 255)" strokeWidth="1px" strokeLinecap="butt" strokeLinejoin="miter" fill="none" d="M38.245,381.102 L435.258,28.158 C480.467,-12.32 549.697,-7.964 589.888,37.244 L942.832,434.257 C983.23,479.466 978.955,548.697 933.746,588.888 L536.733,941.832 C491.524,982.23 422.293,977.955 382.103,932.745 L29.158,535.732 C-11.32,490.523 -6.963,421.293 38.245,381.102 Z" />
                    </svg>
                </div>
                <div className="container">
                    <div className="page-banner-wrap">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="page-banner text-center">
                                    <h2 className="title">Our Team</h2>
                                    <ul className="breadcrumb justify-content-center">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Our Team</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Page Banner End */}

            {/* Team Start */}
            <div className="section techwix-team-section techwix-team-section-03 section-padding">
                <div className="container">
                    <div className="team-wrap">
                        <div className="row">
                            <div className="col-lg-3 col-sm-6">
                                <div className="single-team">
                                    <div className="team-img">
                                        <a href="#"><img src={team1} alt="team-1.jpg" /></a>
                                    </div>
                                    <div className="team-content">
                                        <h3 className="name"><a href="#">Pradeep <br /> Kashyap</a></h3>
                                        <span className="designation">Designing Lead</span>
                                        <div className="team-social">
                                            <ul className="social">
                                                <li>
                                                    <Link to="https://www.facebook.com/profile.php?id=61557420182345" target="_blank">
                                                        <i className="fab fa-facebook-f"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="https://twitter.com/bilvaleaf">
                                                        <i className="fab fa-twitter" target="_blank"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="https://www.instagram.com/bilvaleaf/">
                                                        <i className="fab fa-instagram" target="_blank"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="https://www.linkedin.com/company/bilvaleaf-private-limited/">
                                                        <i className="fab fa-linkedin-in" target="_blank"></i>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6">
                                <div className="single-team">
                                    <div className="team-img">
                                        <a href="#"><img src={team2} alt="team-2.jpg" /></a>
                                    </div>
                                    <div className="team-content">
                                        <h3 className="name"><a href="#">Sandeep <br /> Kumar</a></h3>
                                        <span className="designation">Co-Founder, Digital Marketing Head</span>
                                        <div className="team-social">
                                            <ul className="social">
                                                <li>
                                                    <Link to="https://www.facebook.com/profile.php?id=61557420182345" target="_blank">
                                                        <i className="fab fa-facebook-f"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="https://twitter.com/bilvaleaf">
                                                        <i className="fab fa-twitter" target="_blank"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="https://www.instagram.com/bilvaleaf/">
                                                        <i className="fab fa-instagram" target="_blank"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="https://www.linkedin.com/company/bilvaleaf-private-limited/">
                                                        <i className="fab fa-linkedin-in" target="_blank"></i>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6">
                                <div className="single-team">
                                    <div className="team-img">
                                        <a href="#"><img src={team3} alt="team-3.jpg" /></a>
                                    </div>
                                    <div className="team-content">
                                        <h3 className="name"><a href="#">Aashish <br /> Barai</a></h3>
                                        <span className="designation">Co-Founder, Marketing & HR Head</span>
                                        <div className="team-social">
                                            <ul className="social">
                                                <li>
                                                    <Link to="https://www.facebook.com/profile.php?id=61557420182345" target="_blank">
                                                        <i className="fab fa-facebook-f"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="https://twitter.com/bilvaleaf">
                                                        <i className="fab fa-twitter" target="_blank"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="https://www.instagram.com/bilvaleaf/">
                                                        <i className="fab fa-instagram" target="_blank"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="https://www.linkedin.com/company/bilvaleaf-private-limited/">
                                                        <i className="fab fa-linkedin-in" target="_blank"></i>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6">
                                <div className="single-team">
                                    <div className="team-img">
                                        <a href="#"><img src={team4} alt="team-4.jpg" /></a>
                                    </div>
                                    <div className="team-content">
                                        <h3 className="name"><a href="#">Chandan <br /> Sharma</a></h3>
                                        <span className="designation">Co-Founder, IT & Development Head</span>
                                        <div className="team-social">
                                            <ul className="social">
                                                <li>
                                                    <Link to="https://www.facebook.com/profile.php?id=61557420182345" target="_blank">
                                                        <i className="fab fa-facebook-f"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="https://twitter.com/bilvaleaf">
                                                        <i className="fab fa-twitter" target="_blank"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="https://www.instagram.com/bilvaleaf/">
                                                        <i className="fab fa-instagram" target="_blank"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="https://www.linkedin.com/company/bilvaleaf-private-limited/">
                                                        <i className="fab fa-linkedin-in" target="_blank"></i>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6">
                                <div className="single-team">
                                    <div className="team-img">
                                        <a href="#"><img src={team5} alt="team-5.jpg" /></a>
                                    </div>
                                    <div className="team-content">
                                        <h3 className="name"><a href="#">Ramanuj <br /> Barai</a></h3>
                                        <span className="designation">Co-Founder, CEO, Director</span>
                                        <div className="team-social">
                                            <ul className="social">
                                                <li>
                                                    <Link to="https://www.facebook.com/profile.php?id=61557420182345" target="_blank">
                                                        <i className="fab fa-facebook-f"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="https://twitter.com/bilvaleaf">
                                                        <i className="fab fa-twitter" target="_blank"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="https://www.instagram.com/bilvaleaf/">
                                                        <i className="fab fa-instagram" target="_blank"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="https://www.linkedin.com/company/bilvaleaf-private-limited/">
                                                        <i className="fab fa-linkedin-in" target="_blank"></i>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6">
                                <div className="single-team">
                                    <div className="team-img">
                                        <a href="#"><img src={team6} alt="team-6.jpg" /></a>
                                    </div>
                                    <div className="team-content">
                                        <h3 className="name"><a href="#">Sakshi <br /> Chaudhary</a></h3>
                                        <span className="designation">HR & Talent Acquisition Manager</span>
                                        <div className="team-social">
                                            <ul className="social">
                                                <li>
                                                    <Link to="https://www.facebook.com/profile.php?id=61557420182345" target="_blank">
                                                        <i className="fab fa-facebook-f"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="https://twitter.com/bilvaleaf">
                                                        <i className="fab fa-twitter" target="_blank"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="https://www.instagram.com/bilvaleaf/">
                                                        <i className="fab fa-instagram" target="_blank"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="https://www.linkedin.com/company/bilvaleaf-private-limited/">
                                                        <i className="fab fa-linkedin-in" target="_blank"></i>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6">
                                <div className="single-team">
                                    <div className="team-img">
                                        <a href="#"><img src={team7} alt="team-7.jpg" height={'337px'} /></a>
                                    </div>
                                    <div className="team-content">
                                        <h3 className="name"><a href="#">Riya <br /> Chourasiya</a></h3>
                                        <span className="designation">Content writer</span>
                                        <div className="team-social">
                                            <ul className="social">
                                                <li>
                                                    <Link to="https://www.facebook.com/profile.php?id=61557420182345" target="_blank">
                                                        <i className="fab fa-facebook-f"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="https://twitter.com/bilvaleaf">
                                                        <i className="fab fa-twitter" target="_blank"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="https://www.instagram.com/bilvaleaf/">
                                                        <i className="fab fa-instagram" target="_blank"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="https://www.linkedin.com/company/bilvaleaf-private-limited/">
                                                        <i className="fab fa-linkedin-in" target="_blank"></i>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6">
                                <div className="single-team">
                                    <div className="team-img">
                                        <a href="#"><img src={team8} alt="team-8.jpg" height={'337px'} /></a>
                                    </div>
                                    <div className="team-content">
                                        <h3 className="name"><a href="#">Indu <br /> Sharma</a></h3>
                                        <span className="designation">HR Coordinator</span>
                                        <div className="team-social">
                                            <ul className="social">
                                                <li>
                                                    <Link to="https://www.facebook.com/profile.php?id=61557420182345" target="_blank">
                                                        <i className="fab fa-facebook-f"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="https://twitter.com/bilvaleaf">
                                                        <i className="fab fa-twitter" target="_blank"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="https://www.instagram.com/bilvaleaf/">
                                                        <i className="fab fa-instagram" target="_blank"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="https://www.linkedin.com/company/bilvaleaf-private-limited/">
                                                        <i className="fab fa-linkedin-in" target="_blank"></i>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6">
                                <div className="single-team">
                                    <div className="team-img">
                                        <a href="#"><img src={team} alt="team-avatar.png" height={'337px'} /></a>
                                    </div>
                                    <div className="team-content">
                                        <h3 className="name"><a href="#">Aditya <br /> Kumar</a></h3>
                                        <span className="designation">Devops Engineer</span>
                                        <div className="team-social">
                                            <ul className="social">
                                                <li>
                                                    <Link to="https://www.facebook.com/profile.php?id=61557420182345" target="_blank">
                                                        <i className="fab fa-facebook-f"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="https://twitter.com/bilvaleaf">
                                                        <i className="fab fa-twitter" target="_blank"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="https://www.instagram.com/bilvaleaf/">
                                                        <i className="fab fa-instagram" target="_blank"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="https://www.linkedin.com/company/bilvaleaf-private-limited/">
                                                        <i className="fab fa-linkedin-in" target="_blank"></i>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6">
                                <div className="single-team">
                                    <div className="team-img">
                                        <a href="#"><img src={team} alt="team-avatar.png" height={'337px'} /></a>
                                    </div>
                                    <div className="team-content">
                                        <h3 className="name"><a href="#">Neeraj <br /> Sharma</a></h3>
                                        <span className="designation">Full Stack Developer</span>
                                        <div className="team-social">
                                            <ul className="social">
                                                <li>
                                                    <Link to="https://www.facebook.com/profile.php?id=61557420182345" target="_blank">
                                                        <i className="fab fa-facebook-f"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="https://twitter.com/bilvaleaf">
                                                        <i className="fab fa-twitter" target="_blank"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="https://www.instagram.com/bilvaleaf/">
                                                        <i className="fab fa-instagram" target="_blank"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="https://www.linkedin.com/company/bilvaleaf-private-limited/">
                                                        <i className="fab fa-linkedin-in" target="_blank"></i>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Team End --> */}
        </>
    )
}

export default Teams;
