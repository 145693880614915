// src/Component/layout/MainLayout.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from './Layout';  // The layout component with Header/Footer
import HomePage from '../../pages/Home';
import BlogList from '../../pages/BlogList';
import BlogSingle from '../../pages/BlogSingle';
import AboutUs from '../../pages/AboutUs';
import Contact from '../../pages/Contact';
import Teams from '../../pages/Team';
import Service from '../../pages/Service';
import Testimonial from '../../pages/Testimonial';

function MainLayout() {
    return (
        <Router>
            <Layout>
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/about" element={<AboutUs />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/team" element={<Teams />} />
                    <Route path="/service" element={<Service />} />
                    <Route path="/testimonial" element={<Testimonial />} />
                    <Route path="/blog" element={<BlogList />} />
                    <Route path="/blog-details/:title" element={<BlogSingle />} />
                </Routes>
            </Layout>
        </Router>
    );
}

export default MainLayout;
