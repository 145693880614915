import React, { useEffect, useState } from "react";
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import team1 from '../../assets/images/team/team-1.jpg';
import team2 from '../../assets/images/team/team-2.jpg';
import team3 from '../../assets/images/team/team-3.jpg';
import team4 from '../../assets/images/team/team-4.jpg';
import team5 from '../../assets/images/team/team-5.jpg';
import team6 from '../../assets/images/team/team-6.jpg';
import team7 from '../../assets/images/team/team-7.jpg';
import team8 from '../../assets/images/team/team-8.jpg';

import { Link } from "react-router-dom";

const TeamSection = () => {
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 429);
  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 429);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <div className="section techwix-team-section section-padding-02">
      <div className="container">
        {/* Team Wrap Start */}
        <div className="team-wrap">
          <div className="section-title text-center">
            <h3 className="sub-title">With our expert team</h3>
            <h2 className="title">Excellence is not a goal but a standard</h2>
          </div>
          {/* Team Content Wrap Start */}
          <div className="team-content-wrap">
            <div className="swiper-container team-active">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <Swiper
                    spaceBetween={5}
                    slidesPerView={1}
                    breakpoints={{
                      // When screen width is >= 640px (tablet and above), show 3 slides
                      640: {
                        slidesPerView: 4,
                      },
                    }}
                    loop={true}
                    autoplay={{
                      delay: 3000, // 3000 ms (3 seconds)
                      disableOnInteraction: false, // Keeps autoplay working even after user interaction
                    }}
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => console.log(swiper)}
                    pagination={{ clickable: true }}
                  >
                    <SwiperSlide>
                      <div data-aos={isWideScreen ? "fade-left" : "undefined"} className="single-team">
                        <div className="team-img">
                          <Link to="/team">
                            <img src={team1} alt="team-1.jpg" />
                          </Link>
                        </div>
                        <div className="team-content">
                          <h3 className="name">
                            <Link to="/team">
                              Pradeep <br /> Kashyap
                            </Link>
                          </h3>
                          <span className="designation">Designing Lead</span>
                          <div className="team-social">
                            <ul className="social">
                              <li>
                                <Link to="https://www.facebook.com/profile.php?id=61557420182345" target="_blank">
                                  <i className="fab fa-facebook-f"></i>
                                </Link>
                              </li>
                              <li>
                                <Link to="https://twitter.com/bilvaleaf">
                                  <i className="fab fa-twitter" target="_blank"></i>
                                </Link>
                              </li>
                              <li>
                                <Link to="https://www.instagram.com/bilvaleaf/">
                                  <i className="fab fa-instagram" target="_blank"></i>
                                </Link>
                              </li>
                              <li>
                                <Link to="https://www.linkedin.com/company/bilvaleaf-private-limited/">
                                  <i className="fab fa-linkedin-in" target="_blank"></i>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div data-aos={isWideScreen ? "fade-left" : "undefined"} className="single-team">
                        <div className="team-img">
                          <Link to="/team">
                            <img src={team2} alt="team-2.jpg" />
                          </Link>
                        </div>
                        <div className="team-content">
                          <h3 className="name">
                            <Link to="/team">
                              Sandeep <br /> Kumar
                            </Link>
                          </h3>
                          <span className="designation">Co-Founder, Digital Marketing Head</span>
                          <div className="team-social">
                            <ul className="social">
                              <li>
                                <Link to="https://www.facebook.com/profile.php?id=61557420182345" target="_blank">
                                  <i className="fab fa-facebook-f"></i>
                                </Link>
                              </li>
                              <li>
                                <Link to="https://twitter.com/bilvaleaf">
                                  <i className="fab fa-twitter" target="_blank"></i>
                                </Link>
                              </li>
                              <li>
                                <Link to="https://www.instagram.com/bilvaleaf/">
                                  <i className="fab fa-instagram" target="_blank"></i>
                                </Link>
                              </li>
                              <li>
                                <Link to="https://www.linkedin.com/company/bilvaleaf-private-limited/">
                                  <i className="fab fa-linkedin-in" target="_blank"></i>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div data-aos={isWideScreen ? "fade-left" : "undefined"} className="single-team">
                        <div className="team-img">
                          <Link to="/team">
                            <img src={team3} alt="team-3.jpg" />
                          </Link>
                        </div>
                        <div className="team-content">
                          <h3 className="name">
                            <Link to="/team">
                              Aashish <br /> Barai
                            </Link>
                          </h3>
                          <span className="designation">Co-Founder, Marketing & HR Head</span>
                          <div className="team-social">
                            <ul className="social">
                              <li>
                                <Link to="https://www.facebook.com/profile.php?id=61557420182345" target="_blank">
                                  <i className="fab fa-facebook-f"></i>
                                </Link>
                              </li>
                              <li>
                                <Link to="https://twitter.com/bilvaleaf">
                                  <i className="fab fa-twitter" target="_blank"></i>
                                </Link>
                              </li>
                              <li>
                                <Link to="https://www.instagram.com/bilvaleaf/">
                                  <i className="fab fa-instagram" target="_blank"></i>
                                </Link>
                              </li>
                              <li>
                                <Link to="https://www.linkedin.com/company/bilvaleaf-private-limited/">
                                  <i className="fab fa-linkedin-in" target="_blank"></i>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div data-aos={isWideScreen ? "fade-left" : "undefined"} className="single-team">
                        <div className="team-img">
                          <Link to="/team">
                            <img src={team4} alt="team-4.jpg" />
                          </Link>
                        </div>
                        <div className="team-content">
                          <h3 className="name">
                            <Link to="/team">
                              Chandan  <br /> Sharma
                            </Link>
                          </h3>
                          <span className="designation">Co-Founder, IT & Development Head</span>
                          <div className="team-social">
                            <ul className="social">
                              <li>
                                <Link to="https://www.facebook.com/profile.php?id=61557420182345" target="_blank">
                                  <i className="fab fa-facebook-f"></i>
                                </Link>
                              </li>
                              <li>
                                <Link to="https://twitter.com/bilvaleaf">
                                  <i className="fab fa-twitter" target="_blank"></i>
                                </Link>
                              </li>
                              <li>
                                <Link to="https://www.instagram.com/bilvaleaf/">
                                  <i className="fab fa-instagram" target="_blank"></i>
                                </Link>
                              </li>
                              <li>
                                <Link to="https://www.linkedin.com/company/bilvaleaf-private-limited/">
                                  <i className="fab fa-linkedin-in" target="_blank"></i>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div data-aos={isWideScreen ? "fade-left" : "undefined"} className="single-team">
                        <div className="team-img">
                          <Link to="/team">
                            <img src={team5} alt="team-5.jpg" />
                          </Link>
                        </div>
                        <div className="team-content">
                          <h3 className="name">
                            <Link to="/team">
                              Ramanuj <br /> Barai
                            </Link>
                          </h3>
                          <span className="designation">Co-Founder, CEO, Director</span>
                          <div className="team-social">
                            <ul className="social">
                              <li>
                                <Link to="https://www.facebook.com/profile.php?id=61557420182345" target="_blank">
                                  <i className="fab fa-facebook-f"></i>
                                </Link>
                              </li>
                              <li>
                                <Link to="https://twitter.com/bilvaleaf">
                                  <i className="fab fa-twitter" target="_blank"></i>
                                </Link>
                              </li>
                              <li>
                                <Link to="https://www.instagram.com/bilvaleaf/">
                                  <i className="fab fa-instagram" target="_blank"></i>
                                </Link>
                              </li>
                              <li>
                                <Link to="https://www.linkedin.com/company/bilvaleaf-private-limited/">
                                  <i className="fab fa-linkedin-in" target="_blank"></i>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div data-aos={isWideScreen ? "fade-left" : "undefined"} className="single-team">
                        <div className="team-img">
                          <Link to="/team">
                            <img src={team6} alt="team-6.jpg" />
                          </Link>
                        </div>
                        <div className="team-content">
                          <h3 className="name">
                            <Link to="/team">
                              Sakshi <br /> Chaudhary
                            </Link>
                          </h3>
                          <span className="designation">HR & Talent Acquisition Manager</span>
                          <div className="team-social">
                            <ul className="social">
                              <li>
                                <Link to="https://www.facebook.com/profile.php?id=61557420182345" target="_blank">
                                  <i className="fab fa-facebook-f"></i>
                                </Link>
                              </li>
                              <li>
                                <Link to="https://twitter.com/bilvaleaf">
                                  <i className="fab fa-twitter" target="_blank"></i>
                                </Link>
                              </li>
                              <li>
                                <Link to="https://www.instagram.com/bilvaleaf/">
                                  <i className="fab fa-instagram" target="_blank"></i>
                                </Link>
                              </li>
                              <li>
                                <Link to="https://www.linkedin.com/company/bilvaleaf-private-limited/">
                                  <i className="fab fa-linkedin-in" target="_blank"></i>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div data-aos={isWideScreen ? "fade-left" : "undefined"} className="single-team">
                        <div className="team-img">
                          <Link to="/team">
                            <img src={team7} alt="team-7.jpg" />
                          </Link>
                        </div>
                        <div className="team-content">
                          <h3 className="name">
                            <Link to="/team">
                              Riya <br /> Chourasiya
                            </Link>
                          </h3>
                          <span className="designation">Content writer</span>
                          <div className="team-social">
                            <ul className="social">
                              <li>
                                <Link to="https://www.facebook.com/profile.php?id=61557420182345" target="_blank">
                                  <i className="fab fa-facebook-f"></i>
                                </Link>
                              </li>
                              <li>
                                <Link to="https://twitter.com/bilvaleaf">
                                  <i className="fab fa-twitter" target="_blank"></i>
                                </Link>
                              </li>
                              <li>
                                <Link to="https://www.instagram.com/bilvaleaf/">
                                  <i className="fab fa-instagram" target="_blank"></i>
                                </Link>
                              </li>
                              <li>
                                <Link to="https://www.linkedin.com/company/bilvaleaf-private-limited/">
                                  <i className="fab fa-linkedin-in" target="_blank"></i>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div data-aos={isWideScreen ? "fade-left" : "undefined"} className="single-team">
                        <div className="team-img">
                          <Link to="/team">
                            <img src={team8} alt="team-8.jpg" />
                          </Link>
                        </div>
                        <div className="team-content">
                          <h3 className="name">
                            <Link to="/team">
                              Indu <br /> Sharma
                            </Link>
                          </h3>
                          <span className="designation">HR Coordinato</span>
                          <div className="team-social">
                            <ul className="social">
                              <li>
                                <Link to="https://www.facebook.com/profile.php?id=61557420182345" target="_blank">
                                  <i className="fab fa-facebook-f"></i>
                                </Link>
                              </li>
                              <li>
                                <Link to="https://twitter.com/bilvaleaf">
                                  <i className="fab fa-twitter" target="_blank"></i>
                                </Link>
                              </li>
                              <li>
                                <Link to="https://www.instagram.com/bilvaleaf/">
                                  <i className="fab fa-instagram" target="_blank"></i>
                                </Link>
                              </li>
                              <li>
                                <Link to="https://www.linkedin.com/company/bilvaleaf-private-limited/">
                                  <i className="fab fa-linkedin-in" target="_blank"></i>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>

              </div>
              {/* Add Pagination */}
              <div className="swiper-pagination"></div>
            </div>
          </div>
          {/* Team Content Wrap End */}
        </div>
        {/* Team Wrap End */}
      </div>
    </div>
  );
};

export default TeamSection;
